exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contentful-static-page-menu-contentful-static-page-slug-js": () => import("./../../../src/pages/{contentfulStaticPage.menu}/{contentfulStaticPage.slug}.js" /* webpackChunkName: "component---src-pages-contentful-static-page-menu-contentful-static-page-slug-js" */),
  "component---src-pages-einstiegsorte-contentful-spot-slug-js": () => import("./../../../src/pages/einstiegsorte/{contentfulSpot.slug}.js" /* webpackChunkName: "component---src-pages-einstiegsorte-contentful-spot-slug-js" */),
  "component---src-pages-gewaesser-contentful-waterway-slug-js": () => import("./../../../src/pages/gewaesser/{contentfulWaterway.slug}.js" /* webpackChunkName: "component---src-pages-gewaesser-contentful-waterway-slug-js" */),
  "component---src-pages-gewaesser-fluesse-js": () => import("./../../../src/pages/gewaesser/fluesse.js" /* webpackChunkName: "component---src-pages-gewaesser-fluesse-js" */),
  "component---src-pages-gewaesser-seen-js": () => import("./../../../src/pages/gewaesser/seen.js" /* webpackChunkName: "component---src-pages-gewaesser-seen-js" */),
  "component---src-pages-gewaesserereignisse-contentful-waterway-event-notice-slug-js": () => import("./../../../src/pages/gewaesserereignisse/{contentfulWaterwayEventNotice.slug}.js" /* webpackChunkName: "component---src-pages-gewaesserereignisse-contentful-waterway-event-notice-slug-js" */),
  "component---src-pages-hindernisse-contentful-obstacle-slug-js": () => import("./../../../src/pages/hindernisse/{contentfulObstacle.slug}.js" /* webpackChunkName: "component---src-pages-hindernisse-contentful-obstacle-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offene-daten-api-js": () => import("./../../../src/pages/offene-daten/api.js" /* webpackChunkName: "component---src-pages-offene-daten-api-js" */)
}

